import React from 'react'
import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image'
import style from './hero.mod.scss'
import { Container, Block } from '@components'

const Hero = ({ image, mobileImage, heading }) => {
  const images = withArtDirection(getImage(image), [
    {
      media: '(max-width: 700px)',
      image: getImage(mobileImage)
    }
  ])

  return (
    <div className={style.hero}>
      <div className={style.hero__image}>
        <GatsbyImage
          className={style[`hero__gatsby-image`]}
          image={images}
          alt={image.alt}
        />
      </div>
      <div className={style.hero__heading}>
        <Block gutters>
          <Container maxWidth={'medium'}>
            <h1>{heading}</h1>
          </Container>
        </Block>
      </div>
    </div>
  )
}

export default Hero
