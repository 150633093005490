import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import style from './modal.mod.scss'
import { isBrowser } from '@context/themeContext'
import ReactDOM from 'react-dom'

const windowAvailable = isBrowser()

const appRoot = windowAvailable && window.document.querySelector('#___gatsby')

const Modal = ({ video, modifiers = [] }) => {
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div className={[style.modal, ...modifiers].join(' ')}>
      <div className={style.modal__wrap}>
        {video &&
          windowAvailable &&
          ReactDOM.createPortal(
            <ModalVideo
              isOpen={isOpen}
              channel={video.provider}
              videoId={video.providerUid}
              onClose={handleClose}
              autoplay
            />,
            appRoot
          )}
        <button
          aria-label={`Open modal and play video: ${video.title}`}
          className={[style.modal__button, 'swiper-no-swiping'].join(' ')}
          onClick={handleOpen}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.94 45">
            <path
              d="M61.45 3.86C59.15 1.12 54.88 0 46.75 0H17.2C8.88 0 4.54 1.2 2.24 4.11 0 6.96 0 11.16 0 16.96v11.07C0 39.28 2.66 45 17.2 45h29.54c7.06 0 10.97-.99 13.5-3.4 2.6-2.5 3.7-6.55 3.7-13.56V16.96c0-6.12-.17-10.34-2.49-13.1Zm-20.4 20.17-13.41 7.01a2.06 2.06 0 0 1-3.02-1.83V15.23a2.06 2.06 0 0 1 3.01-1.83l13.42 6.97a2.06 2.06 0 0 1 0 3.66Z"
              fill="#fff"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default Modal
