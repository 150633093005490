import React from 'react'
import style from './footer.mod.scss'
import { Button } from '@components'

const Footer = ({ menuItems }) => {
  const classes = [style.footer]

  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className={classes.join(' ')}>
      <div className={style.footer__container}>
        <FooterNav links={menuItems} />
      </div>
      <div className={style.footer__credits}>
        <p>
          {`© ${year} Huadu International | Designed and Developed by `}
          <Button
            unstyled
            to={'https://www.harveycameron.nz/'}
            children={'Harvey Cameron'}
          />
        </p>
      </div>
    </footer>
  )
}

const FooterNav = ({ links }) => {
  const items = [...links]

  items.push({
    path: `/disclosures-privacy/`,
    title: 'Privacy',
    id: 'privacy-link'
  })

  return (
    <div className={style.footer__nav}>
      <nav>
        <ul>
          {items.map(item => (
            <li key={item.id}>
              <Button
                to={item.path || item.url}
                children={item.title}
                unstyled
              />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default Footer
