import React from 'react'
import LogoHuadu from '@svgs/logo.svg'
import { Link } from 'gatsby'
import style from './logo.mod.scss'

const Logo = ({ modifiers = [style.logo] }) => {
  return (
    <Link to="/" aria-label={`Home`}>
      <div className={[...modifiers].join(' ')}>
        <LogoHuadu />
      </div>
    </Link>
  )
}

export default Logo
