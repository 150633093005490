import React from 'react'
import style from './business-info-list.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button, Container } from '@components'

const BusinessInfoList = ({ businesses }) => {
  return (
    <Container maxWidth={'large'}>
      <div className={style[`business-list`]}>
        {businesses.map(item => (
          <BusinessItem {...item} />
        ))}
      </div>
    </Container>
  )
}

export default BusinessInfoList

const BusinessItem = ({ heading, logo, content, button }) => {
  return (
    <div className={style[`business-list__item`]}>
      <div className={style[`business-list__logo`]}>
        <GatsbyImage
          objectFit="contain"
          image={logo.gatsbyImageData}
          alt={logo.alt}
        />
      </div>
      <div className={style[`business-list__info`]}>
        <h3 className={style[`business-list__heading`]}>{heading}</h3>
        <div
          className={style[`business-list__content`]}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {(button?.link?.path || button?.url) && (
          <div className={style[`business-list__button`]}>
            <Button to={button?.link?.path || button?.url}>
              {button.displayText}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
