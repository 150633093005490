import React from 'react'
import style from './status-label.mod.scss'

const StatusLabel = ({ text }) => {
  return (
    <div className={style['status-label']}>
      <h4>{text}</h4>
    </div>
  )
}

export default StatusLabel
