import React, { useEffect } from 'react'
import style from './select.mod.scss'

const Select = React.forwardRef(
  (
    {
      row = false,
      fullWidth = false,
      strongBorder,
      label,
      name,
      options,
      ErrorMessage,
      helpMessage,
      modifier,
      onChange,
      location,
      ...selectOptions
    },
    ref
  ) => {
    const modifiers = []

    ErrorMessage && modifiers.push(style['select--error'])
    modifier && modifiers.push(style[`select--${modifier}`])
    row && modifiers.push(style[`select--row`])
    fullWidth && modifiers.push(style[`select--full-width`])
    strongBorder && modifiers.push(style[`select--strong-border`])

    const selectConfig = {
      id: `select--${name}`,
      name,
      ref,
      ...selectOptions
    }

    useEffect(() => {
      if (!location?.state?.category?.slug) return

      ref.current.value = location.state.category.slug

      onChange({
        target: {
          value: ref.current.value,
          name: 'categories'
        }
      })
    }, [])

    return (
      <div className={[style.select, ...modifiers].join(' ')}>
        {label && (
          <label htmlFor={`select--${name}`}>
            {label}
            {selectConfig.required && <span aria-label="required">*</span>}
          </label>
        )}
        <select
          {...selectConfig}
          onChange={onChange && (e => onChange(e, name))}
        >
          {selectConfig.placeholder && (
            <option value="">{selectConfig.placeholder.text}</option>
          )}
          {options.map(({ value, text, disabled }, i) => (
            <option
              key={`select--${name}--${value}-${i}`}
              children={text}
              value={value}
              disabled={disabled}
              aria-label={`Select ${text}`}
            />
          ))}
        </select>
        {helpMessage && <div className={style.select__help}>{helpMessage}</div>}
        {ErrorMessage && (
          <div className={style.select__error}>
            <ErrorMessage />
          </div>
        )}
      </div>
    )
  }
)

export default Select

// import React from 'react'
// import style from './select.mod.scss'

// const Select = React.forwardRef(
//   ({ label, name, placeholder, options, ErrorMessage, required }, ref) => {
//     const modifier = ErrorMessage ? style['select--error'] : ''

//     const selectConfig = {
//       id: `select--${name}`,
//       name,
//       ref,
//       required
//     }

//     return (
//       <div className={[style.select, modifier].join(' ')}>
//         {label && (
//           <label htmlFor={`select--${name}`}>
//             {label}
//             {required && <span area-label="required">*</span>}
//           </label>
//         )}
//         <select {...selectConfig}>
//           {placeholder && <option value="">{placeholder}</option>}
//           {options.map(({ value, text }, i) => (
//             <option
//               key={`select--${name}--${value}-${i}`}
//               aria-label={`select--${name}`}
//               children={text}
//               value={value}
//             />
//           ))}
//         </select>
//         {ErrorMessage && (
//           <div className={style.select__error}>
//             <ErrorMessage />
//           </div>
//         )}
//       </div>
//     )
//   }
// )

// export default Select
