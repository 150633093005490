import React from 'react'
import { graphql } from 'gatsby'
import style from './project-info.mod.scss'
import { Container } from '@components'
const ProjectInfo = ({
  blurb,
  location,
  architects,
  buildingSize,
  landSize,
  projectStatus
}) => {
  return (
    <Container maxWidth={'large'}>
      <div className={style[`project-info`]}>
        <div className={style[`project-info__info`]}>
          {location && (
            <h4>
              <span>Location:</span>
              {location}
            </h4>
          )}
          {landSize && (
            <h4>
              <span>Land Size:</span>
              {landSize}
            </h4>
          )}
          {buildingSize && (
            <h4>
              <span>Building Size:</span>
              {buildingSize}
            </h4>
          )}
          {architects && (
            <h4>
              <span>Architects:</span>
              {architects}
            </h4>
          )}
          {projectStatus && (
            <h4>
              <span>Project Status:</span>
              {projectStatus}
            </h4>
          )}
        </div>
        <div
          className={style[`project-info__blurb`]}
          dangerouslySetInnerHTML={{ __html: blurb }}
        />
      </div>
    </Container>
  )
}

export default ProjectInfo

export const query = graphql`
  fragment ProjectInfo on DatoCmsProjectInfo {
    projectStatus
    paddingTop
    paddingBottom
    location
    landSize
    blurb
    architects
    buildingSize
    __typename
  }
`
