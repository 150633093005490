import React from 'react'
import { graphql } from 'gatsby'
import { Carousel, Card } from '@components'

const DatoCmsPartnersSlider = ({ ...props }) => {
  return (
    <Carousel
      config={{
        spaceBetween: 0,
        navigation: true,
        pagination: true,
        slidesPerView: 3,
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          700: {
            slidesPerView: 2
          },
          1200: {
            slidesPerView: 3,
            pagination: false
          }
        }
      }}
      {...props}
    >
      {props.partners.map((slide, index) => {
        return (
          <Card
            key={`card-slide-${props.id}-${slide.id}`}
            heading={slide.title}
            description={slide.description}
            image={slide.logo}
            slide
          />
        )
      })}
    </Carousel>
  )
}

export default DatoCmsPartnersSlider

export const query = graphql`
  fragment PartnersSlider on DatoCmsPartnersSlider {
    id
    paddingBottom
    paddingTop
    background
    partners {
      description
      logo {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fit: "crop", h: "300", w: "500", q: 60 }
        )
      }
      title
    }
    __typename
  }
`
