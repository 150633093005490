import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './card-large.mod.scss'
import { Button, Container } from '@components'

const CardLarge = ({
  heading,
  subHeading,
  content,
  button,
  image,
  isSlide
}) => {
  const modifiers = []
  isSlide && modifiers.push(style[`card-large--slide`])

  return (
    <Container maxWidth={'large'}>
      <div className={[style[`card-large`], ...modifiers].join(' ')}>
        <div className={style[`card-large__image`]}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        </div>
        <div className={style[`card-large__content`]}>
          <h3>{heading}</h3>
          {subHeading && <h5>{subHeading}</h5>}
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {(button?.link?.path || button?.url) && (
            <div className={style[`card-large__button`]}>
              <Button to={button?.link?.path || button?.url}>
                {button.displayText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default CardLarge

export const query = graphql`
  fragment ContentCard on DatoCmsContentCard {
    button {
      ...Link
    }
    heading
    id
    background
    paddingTop
    paddingBottom
    content
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "800", w: "1200", fit: "crop", q: 60 }
      )
      alt
    }
    __typename
  }
`
