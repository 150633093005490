import React from 'react'
import { Carousel, Image } from '@components'
import { graphql } from 'gatsby'

const DatoCmsSlider = ({ ...props }) => {
  return (
    <>
      <Carousel
        config={{
          navigation: true,
          slidesPerView: 1
        }}
        {...props}
      >
        {props.images.map(image => {
          return (
            <Image
              key={`slider-${props.id}-${image.id}`}
              image={image}
              width={'slide'}
            />
          )
        })}
      </Carousel>
    </>
  )
}

export default DatoCmsSlider

export const query = graphql`
  fragment Slider on DatoCmsSlider {
    id
    paddingBottom
    paddingTop
    images {
      gatsbyImageData(
        layout: CONSTRAINED
        imgixParams: { h: "600", w: "1170", fit: "crop", q: 60 }
      )
      alt
    }
    __typename
  }
`
