import React from 'react'
import { Block, Card, Grid } from '@components'
import { useStaticQuery, graphql } from 'gatsby'

const DatoCmsAllProjectsList = ({ paddingTop, paddingBottom, background }) => {
  const data = useStaticQuery(graphql`
    {
      projects: allDatoCmsProject(sort: { fields: position }) {
        nodes {
          id
          originalId
          slug
          title
          position
          path
          statusLabel
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "750", w: "1080", fit: "crop", q: 60 }
            )
            alt
          }
          excerpt
          meta {
            firstPublishedAt(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  `)

  const cards = data.projects.nodes

  console.log(cards)

  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <Grid columns={2}>
        {cards.map(card => {
          return (
            <Card
              key={`card-grid-card-${card.id}`}
              contained={false}
              image={card.heroImage}
              heading={card.title}
              description={card.excerpt}
              button={{
                link: { path: card.path },
                displayText: 'Find out more'
              }}
              label={card.statusLabel}
            />
          )
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsAllProjectsList

export const query = graphql`
  fragment AllProjectsList on DatoCmsAllProjectsList {
    id
    paddingBottom
    paddingTop
    background
    __typename
    model {
      apiKey
    }
  }
`
