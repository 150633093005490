import React from 'react'
import { Block, ContactInfo } from '@components'

const DatoCmsContactInfo = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <ContactInfo {...props} />
    </Block>
  )
}

export default DatoCmsContactInfo
