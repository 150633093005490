import React from 'react'
import style from './post-meta.mod.scss'

const PostMeta = ({ categories, date }) => {
  return (
    <p className={style.meta}>
      <span className={style.meta__date}>{date}</span>
      {categories.map(item => (
        <span key={`category-${item.title}`} className={style.meta__category}>
          {item.title}
        </span>
      ))}
    </p>
  )
}

export default PostMeta
