import React from 'react'
import { Block, Button, Container } from '@components'

const DatoCmsButton = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Container>
        <Button {...props} to={props.link?.link?.path || props.link?.url}>
          {props.link.displayText}
        </Button>
      </Container>
    </Block>
  )
}

export default DatoCmsButton
