import React from 'react'
import { graphql } from 'gatsby'
import { Carousel, Card } from '@components'

const DatoCmsCurrentProjectSlider = ({ ...props }) => {
  return (
    <Carousel
      config={{
        spaceBetween: 40,
        navigation: true,
        pagination: true,
        slidesPerView: 3,
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          700: {
            slidesPerView: 2
          },
          1200: {
            slidesPerView: 3,
            pagination: false
          }
        }
      }}
      {...props}
    >
      {props.projects.map((card, index) => {
        return (
          <Card
            key={`card-grid-card-${card.id}`}
            contained={false}
            image={card.heroImage}
            heading={card.title}
            description={card.excerpt}
            button={{
              link: { path: card.path },
              displayText: 'Find out more'
            }}
            label={card.statusLabel}
            slide
          />
        )
      })}
    </Carousel>
  )
}

export default DatoCmsCurrentProjectSlider

export const query = graphql`
  fragment CurrentProjectSlider on DatoCmsCurrentProjectSlider {
    paddingBottom
    paddingTop
    background
    projects {
      id
      title
      statusLabel
      path
      excerpt
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "750", w: "1080", fit: "crop", q: 60 }
        )
        alt
      }
    }
    __typename
  }
`
