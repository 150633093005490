import React, { useContext } from 'react'
import style from './forms.mod.scss'
import { Input, Textarea, Checkbox, Button } from '@components'
import ThemeContext from '@context/themeContext'
import { useStaticQuery, graphql } from 'gatsby'

const FormsContact = () => {
  const { locale, setLocale } = useContext(ThemeContext)

  const data = useStaticQuery(graphql`
    {
      en: contentYaml(language: { eq: "en" }) {
        ...FormTranslate
      }
    }
  `)

  const content = data.en

  return (
    <>
      <div className={style.form__double}>
        <Input
          type="text"
          label={content.form.firstName.label}
          placeholder={content.form.firstName.placeholder}
          name="firstName"
          required
        />
        <Input
          type="text"
          label={content.form.lastName.label}
          placeholder={content.form.lastName.placeholder}
          name="lastName"
          required
        />
      </div>
      <Input
        type="email"
        label={content.form.email.label}
        placeholder={content.form.email.placeholder}
        name="email"
        required
      />
      <Input
        type="text"
        label={content.form.subject.label}
        placeholder={content.form.subject.placeholder}
        name="subject"
        required
      />
      <Textarea
        label={content.form.message.label}
        placeholder={content.form.message.placeholder}
        required
        name="message"
      />
      <Checkbox
        name="policy"
        required
        label={
          <span>
            {content.form.policy.intro}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/disclosures-privacy/`}
            >
              {content.form.policy.link}
            </a>
          </span>
        }
      />
      <div className={style.form__submit}>
        <Button type="submit">{content.form.submit.label}</Button>
      </div>
    </>
  )
}

export default FormsContact
