import React from 'react'
import { Block, Container, Form } from '@components'

const DatoCmsCta = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={'double'}
      paddingBottom={'double'}
      background={'dark'}
      backgroundStyle={backgroundStyle}
    >
      <Container>
        <Form {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsCta
