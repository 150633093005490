import React from 'react'
import style from './heading.mod.scss'
import { Button, Container } from '@components'

const Heading = ({ heading, button }) => {
  return (
    <Container maxWidth="large">
      <div className={style.heading}>
        <h2>{heading}</h2>
        {(button?.link?.path || button?.url) && (
          <div>
            <Button
              to={button?.link?.path || button.url}
              children={button.displayText}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default Heading
