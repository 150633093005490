import React from 'react'
import { graphql } from 'gatsby'
import style from './contact-info.mod.scss'
import { Container } from '@components'
import { BiPhoneCall, BiMap, BiEnvelope } from 'react-icons/bi'

const ContactInfo = ({ address, phone, email }) => {
  return (
    <Container>
      <div className={style[`contact-info`]}>
        <div>
          <BiMap />
          <p>{address}</p>
        </div>
        <div>
          <BiPhoneCall />
          <p>{phone}</p>
        </div>
        <div>
          <BiEnvelope />
          <p>{email}</p>
        </div>
      </div>
    </Container>
  )
}

export default ContactInfo

export const query = graphql`
  fragment ContactInfo on DatoCmsContactInfo {
    id
    paddingTop
    paddingBottom
    background
    __typename
    address
    phone
    email
  }
`
