import React from 'react'
import { Block, Container, Video } from '@components'

const DatoCmsVideo = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Container maxWidth={'large'}>
        <Video {...props} />
      </Container>
    </Block>
  )
}

export default DatoCmsVideo
