import React from 'react'
import { Block, Card, Grid, Heading } from '@components'
import { useStaticQuery, graphql } from 'gatsby'

const DatoCmsLatestNews = ({
  paddingTop,
  paddingBottom,
  background,
  locale,
  heading,
  button
}) => {
  const data = useStaticQuery(graphql`
    {
      enPosts: allDatoCmsPost(
        filter: { locale: { eq: "en" } }
        limit: 3
        sort: { order: DESC, fields: meta___firstPublishedAt }
      ) {
        nodes {
          id
          originalId
          slug
          title
          path
          categories {
            id
            slug
            title
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "750", w: "1080", fit: "crop", q: 60 }
            )
            alt
          }
          excerpt
          categories {
            title
          }
          meta {
            firstPublishedAt(formatString: "DD MMMM YYYY")
          }
        }
      }
      enContent: contentYaml(language: { eq: "en" }) {
        news {
          button
        }
      }
    }
  `)

  const buttonText = data.enContent.news.button

  const cards = data.enPosts.nodes

  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <Block gutters>
        <Heading heading={heading} button={button} />
      </Block>
      <Grid columns={3} scrollMobile>
        {cards.map(card => {
          return (
            <Card
              key={`card-grid-card-${card.id}`}
              contained={false}
              image={card.image}
              heading={card.title}
              subHeading={card.meta.firstPublishedAt}
              description={card.excerpt}
              button={{ link: { path: card.path }, displayText: buttonText }}
            />
          )
        })}
      </Grid>
    </Block>
  )
}

export default DatoCmsLatestNews

export const query = graphql`
  fragment LatestNews on DatoCmsLatestNews {
    id
    paddingBottom
    paddingTop
    background
    heading
    button {
      ...Link
    }
    __typename
    model {
      apiKey
    }
  }
`
