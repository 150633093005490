import React from 'react'
import { graphql } from 'gatsby'
import { Block, BusinessInfoList } from '@components'

const DatoCmsBusinessInfoList = ({
  paddingTop,
  paddingBottom,
  background,
  ...props
}) => {
  return (
    <Block
      gutters
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <BusinessInfoList {...props} />
    </Block>
  )
}

export default DatoCmsBusinessInfoList

export const query = graphql`
  fragment BusinessList on DatoCmsBusinessInfoList {
    id
    paddingBottom
    paddingTop
    background
    businesses {
      content
      logo {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fit: "crop", w: "500", q: 60 }
        )
      }
      heading
      button {
        ...Link
      }
    }
    __typename
  }
`
