import React, { useContext } from 'react'
import style from './forms.mod.scss'
import { useStaticQuery, graphql } from 'gatsby'
import { Input, Checkbox, Button, Select, Textarea } from '@components'
import ThemeContext from '@context/themeContext'

const FormsRegister = () => {
  const { locale } = useContext(ThemeContext)

  const data = useStaticQuery(graphql`
    {
      enContent: contentYaml(language: { eq: "en" }) {
        ...FormTranslate
      }
    }
  `)

  const content = data.enContent

  return (
    <>
      <div className={style.form__double}>
        <Input
          type="text"
          label={content.form.firstName.label}
          placeholder={content.form.firstName.placeholder}
          name="firstName"
          required
        />
        <Input
          type="text"
          label={content.form.lastName.label}
          placeholder={content.form.lastName.placeholder}
          name="lastName"
          required
        />
      </div>
      <Input
        type="email"
        label={content.form.email.label}
        placeholder={content.form.email.placeholder}
        name="email"
        required
      />
      <Input
        type="tel"
        label={content.form.phone.label}
        placeholder={content.form.phone.placeholder}
        name="phone"
        required
      />
      <Textarea
        label={content.form.message.label}
        placeholder={content.form.message.placeholder}
        required
        name="message"
      />
      {/* <Select
        required
        name="investment"
        label={content.form.select.label}
        options={getOptions()}
        placeholder={{ text: `${content.form.select.placeholder}` }}
      /> */}
      <Checkbox label={content.form.checkbox.label} name="checkbox" />
      <Checkbox
        name="policy"
        required
        label={
          <span>
            {content.form.policy.intro}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`/${locale}/disclosures-privacy`}
            >
              {content.form.policy.link}
            </a>
          </span>
        }
      />
      <div className={style.form__submit}>
        <Button type="submit" color={'inverse'}>
          {content.form.registerInterest.label}
        </Button>
      </div>
    </>
  )
}

export default FormsRegister
