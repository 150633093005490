import React from 'react'
import PropTypes from 'prop-types'
import style from './container.mod.scss'

const Container = ({ children, id, maxWidth = 'Medium', center = false }) => {
  const modifiers = [
    style[`container--max-width-${maxWidth.toLowerCase().replace(/ /gi, '-')}`]
  ]

  center && modifiers.push(style[`container--centered`])

  const config = {
    className: [style.container, ...modifiers].join(' '),
    id,
    children
  }

  return <div {...config} />
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container
