import React from 'react'
import { graphql } from 'gatsby'
import style from './cta.mod.scss'
import { Button, Block } from '@components'

const Cta = ({ heading, content, link }) => {
  return (
    <div className={style.cta}>
      <Block paddingTop={'double'} paddingBottom={'double'} gutters>
        <div className={style.cta__wrap}>
          <h2>{heading}</h2>
          <div
            className={style.cta__content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {link && (
            <Button to={link?.link?.path || link?.url}>
              {link.displayText}
            </Button>
          )}
        </div>
      </Block>
    </div>
  )
}

export default Cta

export const query = graphql`
  fragment Cta on DatoCmsCta {
    id
    heading
    content
    button {
      ...Link
    }
    __typename
  }
`
