import React from 'react'
import { Block, FilteredList } from '@components'
import { useStaticQuery, graphql } from 'gatsby'

const FilteredBlogList = ({
  paddingTop,
  paddingBottom,
  background,
  locale,
  backgroundStyle,
  ...props
}) => {
  const getAllPosts = useStaticQuery(graphql`
    {
      allDatoCmsPost(
        limit: 3
        sort: { order: DESC, fields: meta___firstPublishedAt }
      ) {
        nodes {
          id
          originalId
          slug
          title
          path
          categories {
            id
            slug
            title
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: { h: "750", w: "1080", fit: "crop", q: 60 }
            )
            alt
          }
          excerpt
          categories {
            title
          }
          meta {
            firstPublishedAt(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  `)
  const items = getAllPosts.allDatoCmsPost.nodes

  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <FilteredList {...props} items={items} locale={locale} />
    </Block>
  )
}

export default FilteredBlogList
