import React from 'react'
import style from './feature-item.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const FeatureItem = ({ image, heading, subHeading, text }) => {
  return (
    <div className={style[`feature-item`]}>
      <div className={style[`feature-item__image`]}>
        <GatsbyImage
          className={style[`feature-item__gatsby_image`]}
          image={image.gatsbyImageData}
          alt={image.alt}
        />
      </div>
      {heading && (
        <div className={style[`feature-item__heading`]}>
          <h5>{heading}</h5>
        </div>
      )}
      {subHeading && (
        <div className={style[`feature-item__sub-heading`]}>
          <p>{subHeading}</p>
        </div>
      )}
      <div
        className={style[`feature-item__text`]}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

export default FeatureItem
