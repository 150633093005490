import React from 'react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Block, Container } from '@components'
import style from './carousel.mod.scss'

SwiperCore.use([Navigation, Pagination])

const Carousel = ({
  children,
  id,
  config,
  width = 'full-width',
  type = 'default',
  scaleSlides = false,
  heading,
  content,
  paddingTop,
  paddingBottom,
  background
}) => {
  const modifiers = []
  width &&
    modifiers.push(
      `${style.carousel}--${width
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  type &&
    modifiers.push(
      `${style.carousel}--${type
        .trim()
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  scaleSlides && modifiers.push(`${style.carousel}--scale`)

  const slides = children.map((slide, i) => {
    return <SwiperSlide key={`${id}-slide-${i}`}>{slide}</SwiperSlide>
  })
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
    >
      <div className={[style.carousel, ...modifiers].join(' ')}>
        <Container>
          <div className={style.carousel__intro}>
            {heading && <h2>{heading}</h2>}
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          </div>
        </Container>
        <Swiper
          slidesPerView={1}
          pagination={{ clickable: true }}
          grabCursor
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          {...config}
        >
          {slides}
        </Swiper>
      </div>
    </Block>
  )
}

export default Carousel
