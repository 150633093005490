import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import style from './forms.mod.scss'
import FormsContact from './forms-contact'
import FormsRegister from './forms-register'
import smoothscroll from 'smoothscroll-polyfill'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Form = ({
  successMessage = 'Thank you for your message, we will be in touch shortly.',
  formColor = 'Primary',
  formType,
  formName,
  leadText,
  heading
}) => {
  const [formState, setFormState] = useState('ready')

  const modifiers = [
    `${style.form}--color-${formColor.toLowerCase().replace(/ /gi, '-')}`
  ]

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    const email = e.target.email.value
    e.target.checkbox.checked &&
      addToMailchimp(email, {
        FNAME: e.target.firstName.value,
        LNAME: e.target.lastName.value
      }).then(data => {
        console.log(data)
      })
    let myForm = document.getElementById('contactForm')
    let formData = new FormData(myForm)
    setFormState('loading')
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(response => {
        if (response.status === 200) onSuccess(response)
        console.log(response)
      })
      .catch(error => {
        setFormState('error')
        console.log(error)
      })

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  const onSuccess = response => {
    console.log('Form successfully submitted', response)
    setFormState('success')
  }

  return (
    <>
      <div className={style.form__wrapper}>
        {heading && <h2>{heading}</h2>}
        {leadText && (
          <div
            className={style.form__lead}
            dangerouslySetInnerHTML={{ __html: leadText }}
          />
        )}
        <form
          id="contactForm"
          name={formName}
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value={formName} />
          <div className={[style.form, ...modifiers].join(' ')}>
            {formState !== 'error' &&
              formState !== 'success' &&
              getForm(formType)}
            {formState === 'error' && (
              <div className={style.form__error}>
                <p>{'Something went wrong. Please try again later.'}</p>
              </div>
            )}
            {formState === 'success' && (
              <div className={style.form__success}>
                <p>{successMessage}</p>
              </div>
            )}
          </div>
          {/* Misc Tracking fields */}
          <input type="hidden" name="type" value={formType} />
        </form>
      </div>
    </>
  )
}

const getForm = formType => {
  if (convertToKebabCase(formType) === 'contact') {
    return <FormsContact />
  }
  if (convertToKebabCase(formType) === 'register-your-interest') {
    return <FormsRegister />
  }
}

const convertToKebabCase = string => {
  return string.replace(/\s+/g, '-').toLowerCase()
}

export default Form

export const query = graphql`
  fragment Form on DatoCmsForm {
    id
    model {
      apiKey
    }
    formType
    formName
    leadText
    heading
    __typename
  }
  fragment FormTranslate on ContentYaml {
    form {
      firstName {
        label
        placeholder
      }
      lastName {
        label
        placeholder
      }
      email {
        label
        placeholder
      }
      checkbox {
        label
      }
      subject {
        label
        placeholder
      }
      message {
        label
        placeholder
      }
      policy {
        intro
        link
      }
      submit {
        label
      }
      registerInterest {
        label
      }
      phone {
        label
        placeholder
      }
      select {
        label
        placeholder
      }
    }
  }
`
